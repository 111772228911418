/* ----------------------------------------------------------------------------------
import読み込み
---------------------------------------------------------------------------------- */
import {
    resetStyleFunc
} from './common/_common';
import {
    hoverFunc,
    telLinkFunc
} from './common/_hoverFunc';
import {
    headerScrollFunc,
    headerCurrentFunc,
    spBtnFunc,
    responsHeaderFunc
} from './common/_header';
headerCurrentFunc();
import {
    resizeFuncCheck,
    resizeFunc
} from './common/_resizecheck';
import {
    heightLine,
    heightLineSetFunc,
    heightLineFunc
} from './common/_heightLine';
import {
    indexFunc,
    visualFunc,
} from './pages/_index.js';
import {
    memberFunc
} from './pages/_member';
import {
    pageLinkAnimationFunc
} from './common/_pageLinkAnimation';

import {
    slideFunc
} from './lib/_slide';
/* ----------------------------------------------------------------------------------
通常実行
---------------------------------------------------------------------------------- */

/*階層取得*/
var loc, dir, dirFlag;
loc = location.pathname;
dir = loc.substring(0, loc.lastIndexOf('/')) + '/';
dirFlag;
dirFlag = false;
if (dir === '/') {
    dirFlag = true;
} else {
    dirFlag = false;
}
/* ----------------------------------------------------------------------------------
window実行
---------------------------------------------------------------------------------- */
// ロード時
var articleWidth, windowWidth;
var count = 0;
windowWidth = 'article';
var scrolledss;
$(window).on('load', function () {
    articleWidth = $(windowWidth).width();
    count = ++count;
    headerScrollFunc();
    spBtnFunc();
    heightLine();
    heightLineSetFunc();
    pageLinkAnimationFunc();
    resetStyleFunc();
    memberFunc();
    if (dirFlag) {
        slideFunc();
        visualFunc();
    }
});

// リサイズ時

$(window).on('resize', function () {
    articleWidth = $(windowWidth).width();
    count = ++count;
    responsHeaderFunc();
    heightLineFunc();
    resetStyleFunc();
});

// スクロール時
var scrolled;
$(window).on('scroll', function () {
    scrolled = $(window).scrollTop();
    if (dirFlag) {
        indexFunc(scrolled);
    }
});


/* ----------------------------------------------------------------------------------
ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
        navigator.userAgent.indexOf('iPhone') > 0) ||
    (navigator.userAgent.indexOf('iPod') > 0) ||
    (navigator.userAgent.indexOf('iPad') > 0) ||
    (navigator.userAgent.indexOf('Android') > 0) ||
    (navigator.userAgent.indexOf('BlackBerry') > 0) ||
    (navigator.userAgent.indexOf('PlayBook') > 0) ||
    (navigator.userAgent.indexOf('Kindle') > 0) ||
    (navigator.userAgent.indexOf('Silk') > 0) ||
    (navigator.userAgent.indexOf('Windows Phone') > 0) ||
    (navigator.userAgent.indexOf('Nokia') > 0)
) {
    // スマホ・タブレット時の処理
    uaFlag = 1;
} else {
    // PC時の処理
    uaFlag = 3;
    hoverFunc();
    telLinkFunc();
}