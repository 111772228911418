import 'slick-carousel';
export function slideFunc() {
    $('#slideWrap').slick();
//    $slider.slick({
//        autoplay: true,
//        autoplaySpeed: 12000,
//        speed: 1000,
//        swipe: false,
//        swipeToSlide: false,
//        responsive: [
//            {
//                breakpoint: 1024,
//                settings: {
//                    swipe: true,
//                    swipeToSlide: true,
//                }
//            }
//        ]
//    });
//
//    /*スライド切り替え前のイベント
//-------------------------------------------------------------*/
//    $slider.on('beforeChange', function (slick, currentSlide, nextSlide) {
//        //alert('スライド切り替え前のイベント');
////                $('#slideWrap').find('.slick-active').animate({
////                    "opacity": 0
////                }, 1000).animate({
////                    "opacity": 1
////                }, 0);
//    });
//
//    /*ブレイクポイント時のイベント
//    -------------------------------------------------------------*/
//    $slider.on('breakpoint', function (event, slick, breakpoint) {
//        $slider.on('beforeChange', function (slick, currentSlide, nextSlide) {
//        //alert('スライド切り替え前のイベント');
//        //        $('#slideWrap').find('.slick-active').animate({
//        //            "opacity": 0
//        //        }, 1000).animate({
//        //            "opacity": 1
//        //        }, 0);
//    });
//    });
//
//    /*スライド切り替え後のイベント
//    -------------------------------------------------------------*/
//    $slider.on('afterChange', function (slick, currentSlide) {
//        //console.log('スライド切り替え後のイベント');
//        //alert('スライド切り替え後のイベント');
//        $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
//        $('#slideWrap').find('.slick-active').prev().find('.postThumbnail').removeClass('postAnime');
//    });
//
//    /*右ボタン
//    -------------------------------------------------------------*/
//    $('.slick-next').on('click', function () {
//        activeNum = $slider.slick('slickCurrentSlide');
//        activeNum = activeNum + 1;
//        console.log('表示番号は' + activeNum);
//        $slider.slick('slickPause');
//        if (activeNum == 1) {
//            //alert('ここです。');
//            $('#slideWrap li').eq(listSum).animate({
//                "opacity": 0
//            }, 1000).animate({
//                "opacity": 1
//            }, 0);
//            setTimeout(function () {
//                $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
//                $('#slideWrap li').eq(listSum).find('.postThumbnail').removeClass('postAnime');
//                $('#slideWrap li').eq(0).find('.postThumbnail').removeClass('postAnime');
//                $slider.slick('slickPlay');
//            }, 1000);
//        } else {
//            $('#slideWrap').find('.slick-active').prev().animate({
//                "opacity": 0
//            }, 1000).animate({
//                "opacity": 1
//            }, 0);
//            setTimeout(function () {
//                $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
//                $('#slideWrap').find('.slick-active').prev().find('.postThumbnail').removeClass('postAnime');
//                $slider.slick('slickPlay');
//            }, 1000);
//        }
//    });
//
//    $('.slick-prev').on('click', function () {
//        activeNum = $slider.slick('slickCurrentSlide');
//        activeNum = activeNum + 1;
//        $slider.slick('slickPause');
//
//        if (activeNum == listSum) {
//            //alert('呼ばれた気がする');
//            $('#slideWrap li').eq(1).animate({
//                "opacity": 0
//            }, 1000).animate({
//                "opacity": 1
//            }, 0);
//            setTimeout(function () {
//                $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
//                $('#slideWrap li').eq(1).find('.postThumbnail').removeClass('postAnime');
//                $slider.slick('slickPlay');
//            }, 1000);
//        } else {
//            $('#slideWrap').find('.slick-active').next().animate({
//                "opacity": 0
//            }, 1000).animate({
//                "opacity": 1
//            }, 0);
//            setTimeout(function () {
//                $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
//                $('#slideWrap').find('.slick-active').next().find('.postThumbnail').removeClass('postAnime');
//                $slider.slick('slickPlay');
//            }, 1000);
//        }
//    });
}