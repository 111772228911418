import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from './_resizecheck';

export var headerNavi, headerHeight, spWrap;
import PerfectScrollbar from 'perfect-scrollbar';
export function headerScrollFunc() {
    const ps = new PerfectScrollbar('#jsScroll', {
        wheelSpeed: .5,
        minScrollbarLength: 30,
    });
}

var local, check;
//カレント操作(TOPページだけ外す)
export function headerCurrentFunc() {
    headerNavi = '#headerGlobalNav';
    local = window.location;
    check = local.pathname;
    if (check === '/') {
        $(headerNavi).addClass('index');
    }
}

//スマホ時のグローバルナビゲーション動作
var menuBtn, headerWrapLayout, overlay, bodyFixed, spScrolled, bodyFixed2;
export var state, scrollPosi, spScrolled;
state = true;
export function spBtnFunc() {
    menuBtn = '.menuBtn';
    headerWrapLayout = '.headerWrapLayout';
    overlay = '.overlay';
    $(menuBtn).on('click', function () {
        $(this).toggleClass('active');
        $(headerWrapLayout).toggleClass('active');
        $(overlay).toggleClass('overlayIn');

        if ($('body').hasClass('fixed')) {
            $('body').removeClass('fixed');
            state = false;
            bodyFixed2();
        } else {
            $('body').addClass('fixed');
            state = true;
            bodyFixed();
        }
    });

    var bodyElm = $('body');
    bodyFixed = function () {
        if (state == true) {
            scrollPosi = $(window).scrollTop();
            bodyElm.css({
                'position': 'fixed',
                'width': '100%',
                'z-index': '1',
                'top': -scrollPosi
            });
        }
    }
    bodyFixed2 = function () {
        bodyElm.removeAttr('style');
        //scroll位置を調整
        $("html, body").scrollTop(scrollPosi);
    }
}
//レスポンシブ時のPC時にheaderを表示する
export function responsHeaderFunc() {
    resizeFuncCheck();
    resizeFunc();
    spWrap = '#spWrap';
    if (resizecheck != 2) {
        $(spWrap).removeAttr('style');
    }
}