/* ----------------------------------------------------------------------------------
透過ホバー
---------------------------------------------------------------------------------- */
export function hoverFunc() {
	// notHoverクラスは透明度の制御を解除
	$('a:not(.notHover)').hover(function() {
        if($(this).hasClass('original')){
            $(this).stop().animate({opacity:'0.7'}, 300);
        }else{
            $(this).stop().animate({opacity:'0.5'}, 300);
        }
		
	},function() {
		$(this).stop().animate({opacity:'1'}, 300);
	});
}

//電話リンクを無効化
export function telLinkFunc() {
    $('a[href^="tel:"]').css('cursor','default').on('click', function(e) {
        e.preventDefault();
    });
}