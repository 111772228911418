// jQueryプラグイン
import 'jquery.easing';
/* ----------------------------------------------------------------------------------
ページ内リンクのアニメーション
---------------------------------------------------------------------------------- */
export function pageLinkAnimationFunc() {
    $('html').attr('id', 'top'); // トップに戻る時に移動する際のフラグID
    $('a[href^="#"]:not(.notScroll)').each(function () {
        var pageUrl = location.href.split('#')[0];
        if (this.href.indexOf(pageUrl + '#') === 0) {
            var id = this.href.split('#')[1];
            $(this).on('click', function () {
                var resizecheck, position;
                resizecheck = $('#responsibleCheck').width();
                if (resizecheck != 1) {
                    position = $('#' + id).offset().top - 158;
                    console.log(position);
                } else {
                    position = $('#' + id).offset().top;
                }
                $('body,html').animate({
                    scrollTop: position
                }, 1000, 'easeInOutQuart');
                return false;
            });
        }
    });
}


export function pageLinkMenuFunc() {
var jsM, href, positionM, target, setPos,setScr;
    jsM = '.jsM';
    $(jsM).on('click', function () {
        href = $(this).attr("href");
        target = $(href == "#" || href == "" ? 'html' : href);
        positionM = target.offset().top;
        
        setScr = $(window).scrollTop();
        console.log(setScr);
        if (setScr > 0) {
            setPos = positionM - 158;
        } else {
            setPos = positionM - 258;
        }
        
        $('body,html').animate({
            scrollTop: setPos
        }, 1000, 'easeInOutQuart');
        return false;
    });
}

export function pageLinkOutAnimationFunc() {
    var url, id, $target, pos, jsMc;
    jsMc = '#jsMc';
    url = $(location).attr('href');
    if (url.indexOf("?id=") != -1) {
        id = url.split("?id=");
        $target = $('#' + id[id.length - 1]);
        if ($target.length) {
            if ( url.match(/concept/)) {
                pos = $target.offset().top - 258;
            }else{
                pos = $target.offset().top - 258;
            }
            $("html, body").animate({
                scrollTop: pos
            }, 1000);
        }
    }
}