import PerfectScrollbar from 'perfect-scrollbar';
export function memberFunc() {
    var modalBlock, position, EditorName, mainTxt, postList, EditorPhoto;
    var memberNameM, positionM, mainTxtM, postListM, photoM, jsScrollM;
    var scrollpos;
    modalBlock = '#modalBlock';
    memberNameM = '#memberName';
    positionM = '#position';
    mainTxtM = '#txt';
    postListM = '#listBox';
    photoM = '#photo';
    jsScrollM = $('#jsScrollM').length;
    if (jsScrollM) {
        const ps = new PerfectScrollbar('#jsScrollM', {
            wheelSpeed: .5,
            minScrollbarLength: 30,
        });
    }

    $('.jsModal').on('click', function () {

        scrollpos = $(window).scrollTop();
        $('body').addClass('fixedBody').css({
            'top': -scrollpos
        });
        //名前
        EditorName = $(this).find('h2').text();
        //役職
        position = $(this).find('.teamCat').text();
        //サムネイル
        EditorPhoto = $(this).find('.thumbnail').html();
        //メインテキスト
        mainTxt = $(this).find('.txt').text();
        //記事
        postList = $(this).find('.postList').html()
        //書き換え処理
        $(photoM).html(EditorPhoto);
        $(memberNameM).text(EditorName);
        $(positionM).text(position);
        $(mainTxtM).text(mainTxt);
        $(postListM).html(postList);

        $(modalBlock).fadeIn();
    });

    $('.overLay,.closeBtn').on('click', function () {
        $('body').removeClass('fixedBody').css({
            'top': 0
        });
        window.scrollTo(0, scrollpos);
        $(this).parents("#modalBlock").fadeOut();
        $(photoM).empty();
        $(memberNameM).empty();
        $(positionM).empty();
        $(mainTxtM).empty();
        $(postListM).empty();
    });
}