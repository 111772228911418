var KeyVisualWrap, animationFlag, scrollCount;
animationFlag = false;
scrollCount = 0;
KeyVisualWrap = '.idxKeyVisualWrap';
import {
    resizeFuncCheck,
    resizeFunc,
    resizecheck
} from '../common/_resizecheck';
export var ItemSum, movingDistance;
export var recommendationParaItem1, endPositionFlag1, recommendationParaItem2, endPositionFlag2, recommendationParaItem3, endPositionFlag3;
//指定移動距離
recommendationParaItem1 = 70;
recommendationParaItem2 = 70;
recommendationParaItem3 = 60;
endPositionFlag1 = true;
endPositionFlag2 = true;
endPositionFlag3 = true;
export function parallaxFunc(scrolled) {}
var nowscrolled, sumScrolled;
export function animationFunc(scrolled) {}
export function indexFunc(scrolled) {
    scrollCount++;
    if (scrollCount == 1) {
        animationFlag = true;
    }
    /* キービジュアルスライドショー*/
    if (scrolled > 50) {
        $(KeyVisualWrap).fadeOut(3000);
        $('#SlideshowWrap').animate({opacity:1}, 3000 );
        $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
        return;
    }
}

export function visualFunc() {
    /* キービジュアル　時間指定
    -------------------------------------------------------------*/
    setTimeout(function () {
        $(KeyVisualWrap).fadeOut(3000);
        $('#SlideshowWrap').animate({opacity:1}, 3000 );
        $('#slideWrap').find('.slick-active').find('.postThumbnail').addClass('postAnime');
    }, 3000);
}